import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import Helmet from "react-helmet"
import {ImageFilename} from '../utils/common'
import moment from 'moment'
import { FaCheckSquare } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoMdRadioButtonOff } from "react-icons/io";

const TableDiaperCampaign = ({ data, location, pageContext: { cp_id } }) => {

	let targets = [
		// {size:'sss',	type:'tape'},
		// {size:'ss',		type:'tape'},
		// {size:'s',		type:'tape'},
		// {size:'s',		type:'pants'},
		// {size:'m',		type:'tape'},
		// {size:'sm',		type:'pants'},
		// {size:'m',		type:'pants'},
		// {size:'l',		type:'tape'},
		// {size:'l',		type:'pants'},
	]

	let targets2 = [
		// {size:'b',		type:'tape'},
		// {size:'b',		type:'pants'},
		// {size:'bb',		type:'pants'},
		// {size:'bbb',	type:'tape'},
		// {size:'bbb',	type:'pants'},
		// {size:'mizu',	type:'wipe'},
		// {size:'mizu',	type:'handwipe'},
		// {size:'none',	type:'wipe'},
		// {size:'thick',	type:'wipe'},
		// {size:'toire',	type:'wipe'},
	]

	const campaignName = nameCampaign(data, cp_id)
	const title = `${campaignName}単価表`
	let description = `${campaignName}のオムツ・おしりふき1枚あたりの価格を比較してサイズ別に1位から3位までランキング！`

	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')

	// 条件に基づいてitemListをフィルタリングする
	const filteredContentfulItems = data.allContentfulItems.edges.map(edge => {
		let filteredItemList = edge.node.itemList;
		
		if(cp_id === 'CPa'){filteredItemList = filteredItemList.filter(item => item.shopId === 'amazon' && (item.txt.includes('Black Friday Sale') || item.txt.includes('クーポン') || item.txt.includes('らくベビ') || item.txt.includes('GOONPLUS2441') || item.txt.includes('エリエール') || item.txt.includes('花王') || item.txt.includes('P&G') || item.txt.includes('まとめトク') || item.txt.includes('レックまとめ') || item.txt.includes('ポイントアップ') || item.txt.includes('実質無料') || item.txt.includes('サントリー')))} 
		if(cp_id === 'CPb'){filteredItemList = filteredItemList.filter(item => item.shopId === 'amazon' && (item.txt.includes('らくベビ') || item.txt.includes('ポイントアップキャンペーン')))} 
		if(cp_id === 'CPc'){filteredItemList = filteredItemList.filter(item => item.shopId === 'rakuten' && item.txt.includes('ポイントバック'))} 
		if(cp_id === 'CPg'){filteredItemList = filteredItemList.filter(item => item.shopId === 'amazon' && item.shop.includes('定期おトク便'))} 
		if(cp_id === 'CPh'){filteredItemList = filteredItemList.filter(item => item.shopId === 'amazon' && item.shop.includes('フレッシュ'))} 
		if(cp_id === 'CPi'){filteredItemList = filteredItemList.filter(item => item.shopId === 'rakuten' && item.shop.includes('即配マート'))} 

		return {
			...edge,
			node: {
				...edge.node,
				itemList: filteredItemList
			}
		};
	});

  // allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	filteredContentfulItems.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})

	// 全ターゲット
	let _targets = [
		{size:'sss',			type:'tape'},
		{size:'ss',				type:'tape'},
		{size:'s',				type:'tape'},
		{size:'s',				type:'pants'},
		{size:'m',				type:'tape'},
		{size:'sm',				type:'pants'},
		{size:'m',				type:'pants'},
		{size:'l',				type:'tape'},
		{size:'l',				type:'pants'},
		{size:'b',				type:'tape'},
		{size:'b',				type:'pants'},
		{size:'bb',				type:'pants'},
		{size:'bbb',			type:'tape'},
		{size:'bbb',			type:'pants'},
		{size:'mizu',			type:'wipe'},
		{size:'mizu',			type:'handwipe'},
		{size:'none',			type:'wipe'},
		{size:'thick',		type:'wipe'},
		{size:'toire',		type:'wipe'},
		{size:'can_big',	type:'milk_powder'},
		{size:'can_small',type:'milk_powder'},
		{size:'stick',		type:'milk_powder'},
		{size:'cube',			type:'milk_powder'},
		{size:'refill',		type:'milk_powder'},
		{size:'can',			type:'milk_liquid'},
		{size:'pouch',		type:'milk_liquid'},
		{size:'pack',			type:'milk_liquid'},
	]

	// 対象ブランドによるターゲット有無をチェック
	let targetCnt = 0;
	_targets.map(_target => {
		let type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === _target.type )})
		if(!type){
			type = data.allTypesMilkYaml.edges.find(edge => {	return ( edge.node.cid === _target.type )})
		}
		let size = data.allSizesYaml.edges.find(edge => {	return ( edge.node.cid === _target.size )})
		if(!size){
			size = data.allSizesMilkYaml.edges.find(edge => {	return ( edge.node.cid === _target.size )})
		}
		const rankItems = gerRankItem(filteredContentfulItems, data, type, size)
		if(rankItems[0]){
			targetCnt += 1
			_target.num = targetCnt
		}
		else{
			_target.num = 0
		}
	})

	// 1列に並べる数を計算（ターゲット有りの数の半分で小数点は切り上げ）
	const targetNum = targetCnt >= 6 ? Math.ceil(targetCnt/2) : targetCnt

	// 1列目にターゲットをセット
	targets = _targets.filter(_target => 
		0 < _target.num && _target.num <= targetNum
	)
	// 2列目にターゲットをセット
	targets2 = _targets.filter(_target => 
		(targetNum < _target.num)
	)

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": "オムツ単価表",
			"item": "https://hula-la.com/diaper.html"
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`/diaper.html`}>オムツ単価表</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				<div className="content">
					<div class="container"style={{marginRight: `0.0rem`}}>
						<div class="columns is-mobile">
						{ cp_id !== 'CPh' && cp_id !== 'CPi' && (
							<>
							{ cp_id === 'CPa' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPa.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>Amazon SALE</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPa' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPa.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>Amazon SALE</span></Link>
								</div>
							)}
							{ cp_id === 'CPg' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPg.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>定期おトク便</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPg' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPg.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>定期おトク便</span></Link>
								</div>
							)}
							{ cp_id === 'CPb' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPb.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPb' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPb.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ</span></Link>
								</div>
							)}
							{ cp_id === 'CPc' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPc.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>スーパーDEAL</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPc' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPc.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>スーパーDEAL</span></Link>
								</div>
							)}
							</>
						)}
						{ (cp_id === 'CPh' || cp_id === 'CPi') && (
							<>
							{ cp_id === 'CPh' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPh.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>Amazon フレッシュ</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPh' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPh.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>Amazon フレッシュ</span></Link>
								</div>
							)}
							{ cp_id === 'CPi' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPi.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>楽天即配マート</strong></span></Link>
								</div>
							)}
							{ cp_id !== 'CPi' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/CPi.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>楽天即配マート</span></Link>
								</div>
							)}
							</>
						)}
						</div>
					</div>
					<h3 className="title is-6" style={{marginTop: `0.3rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{campaignName}単価表<small> ({update}更新)</small></h3>
					<div class="container" style={{marginRight: `0.0rem`}}>
						<div class="columns is-mobile">
							<div class="column is-5" style={{paddingTop: `0.8rem`, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
							</div>
							<div class="column is-7" style={{paddingBottom: `0.9rem`}}>
							</div>
						</div>
					</div>
					<div className="columns is-multiline is-gapless">
						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table1" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>サイズ</th>
											<th style={{color: `#ffffff`}}>1位</th>
											<th style={{color: `#ffffff`}}>2位</th>
											<th style={{color: `#ffffff`}}>3位</th>
										</tr>
									</thead>
									<tbody>
									{targets.map(target => {
										let type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										if(!type){
											type = data.allTypesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										}								
										let size = data.allSizesYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										if(!size){
											size = data.allSizesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										}								
										const sizeStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
										const itemStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'オムツ' : type.node.description
										const unitStr = ((type.node.cid !== 'milk_powder') && (type.node.cid !== 'milk_liquid'))? '枚' : '100ml' 
										let rankItems = gerRankItem(filteredContentfulItems, data, type, size)
										if(rankItems.lentgh >= 1){
											description += ` ${size.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										if(!rankItems[0]){
											return
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${size.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{size.node.description.replace('小さめ','小').replace('はいはい','小').replace('ビッグより大きい','BIG大').replace('ビッグ','BIG').replace('スーパー','S').replace('トイレに流せる','トイレ')}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{type.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[0], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[1], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[1] && (
													<td>
													</td>
												)}
												{ rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[2], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[2] && (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>

						{ targetCnt >= 6 && (
						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table2" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>サイズ</th>
											<th style={{color: `#ffffff`}}>1位</th>
											<th style={{color: `#ffffff`}}>2位</th>
											<th style={{color: `#ffffff`}}>3位</th>
										</tr>
									</thead>
									<tbody>
									{targets2.map(target => {
										let type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										if(!type){
											type = data.allTypesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										}
										let size = data.allSizesYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										if(!size){
											size = data.allSizesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										}								
										const sizeStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
										const itemStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'オムツ' : type.node.description
										const unitStr = ((type.node.cid !== 'milk_powder') && (type.node.cid !== 'milk_liquid'))? '枚' : '100ml' 
										let rankItems = gerRankItem(filteredContentfulItems, data, type, size)
										if(rankItems.lentgh >= 1){
											description += ` ${size.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										if(!rankItems[0]){
											return
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${size.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{size.node.description.replace('小さめ','小').replace('はいはい','小').replace('ビッグより大きい','BIG大').replace('ビッグ','BIG').replace('スーパー','S').replace('トイレに流せる','トイレ')}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{type.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[0], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[1], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[1] && (
													<td>
													</td>
												)}
												{ rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[2], getShopId(data, rankItems[0]), cp_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/{unitStr}</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[2] &&  (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>
						)}

					</div>
				</div>
			</>
		<Seo title={title} description={description} canonical={location.pathname} />
  </Layout>
	)
}

function gerRankItem(filteredContentfulItems, data, type, size){
	let index = 0
	let rankItems = []
	filteredContentfulItems.map(edge => {
		if(index >= 3){
			return 
		}
		if((!size) || (!type)){
			return;
		}
		if(edge.node.typeId !== type.node.cid || edge.node.sizeId !== size.node.cid) return
		let brand = data.allBrandsYaml.edges.find(n => {
			return n.node.cid === edge.node.brandId
		})
		if(!brand){
			brand = data.allBrandsMilkYaml.edges.find(n => {
				return n.node.cid === edge.node.brandId
			})	
		}
		let subbrand = data.allSubbrandsYaml.edges.find(n => {
			return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
		})
		if(!subbrand){
			subbrand = data.allSubbrandsMilkYaml.edges.find(n => {
				return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
			})	
		}
		if(!brand) return
		if(!edge.node.itemList.length) return
		rankItems[index] = edge
		index = index + 1
	})
	return rankItems
}

function gerBrand(data, edge){
	let brand = data.allBrandsYaml.edges.find(n => {
		return n.node.cid === edge.node.brandId
	})
	if(!brand){
		brand = data.allBrandsMilkYaml.edges.find(n => {
			return n.node.cid === edge.node.brandId
		})	
	}
	return brand
}

function gerSubbrand(data, edge){
	let subbrand = data.allSubbrandsYaml.edges.find(n => {
		return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
	})
	if(!subbrand){
		subbrand = data.allSubbrandsMilkYaml.edges.find(n => {
			return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
		})	
	}
	return subbrand
}

function nameBrand(data, edge){
	const brand = gerBrand(data, edge)
	let ret = `${brand.node.description}`
	if(ret === '恵生堂'){
		ret = 'メグミ'
	}
	return ret;
}

function nameCampaign(data, cp_id){
	let ret = ''
	if(cp_id === 'CPa'){
		ret = '[Amazon]SALE'
	}
	if(cp_id === 'CPg'){
		ret = '[Amazon]定期おトク便'
	}
	if(cp_id === 'CPb'){
		ret = '[Amazon]らくベビ'
	}
	if(cp_id === 'CPc'){
		ret = '[楽天]スーパーDEAL'
	}
	if(cp_id === 'CPh'){
		ret = 'Amazon フレッシュ'
	}
	if(cp_id === 'CPi'){
		ret = '楽天即配マート'
	}
	return ret;
}

function nameSubbrand(data, edge){
	const renames = [
		{brand:'goon', 		subbrand:'sara',	name:'まっさらさら'},
		{brand:'goon', 		subbrand:'mash',	name:'はじめての肌着'},
		{brand:'goon', 		subbrand:'yawa',	name:'やわらか'},
		{brand:'goon', 		subbrand:'gungun',name:'12時間'},
		{brand:'goon', 		subbrand:'gunkyuu_p',name:'プリンセス'},
		{brand:'pampers', subbrand:'sara',	name:'さらケア'},
		{brand:'pampers', subbrand:'sara_oyas',	name:'昼夜セット'},
		{brand:'pampers', subbrand:'haji',	name:'肌いち'},
		{brand:'pampers', subbrand:'haji_oyas',	name:'昼夜セット'},
		{brand:'pampers', subbrand:'sotu',	name:'卒業パンツ'},
		{brand:'pampers', subbrand:'onii',	name:'おにいさん'},
		{brand:'pampers', subbrand:'onee',	name:'おねえさん'},
		{brand:'merries', subbrand:'sara',	name:'エアスルー'},
		{brand:'merries', subbrand:'firs',	name:'メリファ'},
		{brand:'moony', 	subbrand:'more',	name:'モレ0'},
		{brand:'moony', 	subbrand:'moreb',	name:'モレ0(男)'},
		{brand:'moony', 	subbrand:'moreg',	name:'モレ0(女)'},
		{brand:'moony', 	subbrand:'yuru',	name:'ゆるうんち'},
		{brand:'moony', 	subbrand:'mash',	name:'マシュマロ'},
		{brand:'moony', 	subbrand:'mashb',	name:'マシュマロ(男)'},
		{brand:'moony', 	subbrand:'mashg',	name:'マシュマロ(女)'},
		{brand:'moony', 	subbrand:'airb',	name:'ｽｰﾊﾟｰBIG(男)'},
		{brand:'moony', 	subbrand:'airg',	name:'ｽｰﾊﾟｰBIG(女)'},
		{brand:'moony', 	subbrand:'tei',		name:'低刺激'},
		{brand:'moony', 	subbrand:'mashb_oyab',	name:'昼夜セット'},
		{brand:'moony', 	subbrand:'mashg_oyag',	name:'昼夜セット'},
		{brand:'mamypoko',subbrand:'plarail',	name:'トミカ'},
		{brand:'mamypoko', 	subbrand:'dora_yoru',	name:'昼夜セット'},
		{brand:'imama', 	subbrand:'comfort',	name:'イ’ママ'},
		{brand:'mirafeel',subbrand:'adjust',	name:'ミラフィール'},
		{brand:'pigeon', 	subbrand:'yawa',	name:'やわらか厚手'},
		{brand:'pigeon', 	subbrand:'name',	name:'なめらか厚手'},
		{brand:'pigeon', 	subbrand:'prem',	name:'極上厚手'},
		{brand:'pigeon', 	subbrand:'fuwa',	name:'ふわふわ厚手'},
		{brand:'pigeon', 	subbrand:'naga',	name:'おしりナップ'},
		{brand:'showa', 	subbrand:'akapink',	name:'赤ちゃん(ピンク)'},
		{brand:'showa', 	subbrand:'akamin',	name:'赤ちゃん(小さめ)'},
		{brand:'showa', 	subbrand:'inai',	name:'いないいないばぁ'},
		{brand:'lecbe', 	subbrand:'toire',	name:'トイレに流せる'},
		{brand:'lecbe', 	subbrand:'atude',	name:'99.9水分たっぷり'},
		{brand:'wakodo', 	subbrand:'haihai_810',			name:'はいはい'},
		{brand:'wakodo', 	subbrand:'haihai_300',			name:'はいはい'},
		{brand:'wakodo', 	subbrand:'haihai',					name:'はいはい'},
		{brand:'wakodo', 	subbrand:'gungun_830',			name:'ぐんぐん'},
		{brand:'wakodo', 	subbrand:'gungun_300',			name:'ぐんぐん'},
		{brand:'wakodo', 	subbrand:'gungun',					name:'ぐんぐん'},
		{brand:'meiji', 	subbrand:'hohoemi',					name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_cube_27',	name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_cube_21',	name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_milk_120',name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_milk',		name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'step',						name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_cube_28',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_cube_22',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_milk_200',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_milk',				name:'ステップ'},
		{brand:'morinaga',subbrand:'E_akachan_800',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan_300',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan',				name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan_eco',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'hagukumi_800',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_300',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi',				name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_eco',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_milk',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'chirumiru_800',		name:'チルミル'},
		{brand:'morinaga',subbrand:'chirumiru',				name:'チルミル'},
		{brand:'morinaga',subbrand:'chirumiru_eco',		name:'チルミル'},
		{brand:'snowbean',subbrand:'sukoyaka_800',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_300',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_13',			name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_6',			name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_milk',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'tsuyoiko_800',		name:'つよいこ'},
		{brand:'snowbean',subbrand:'tsuyoiko_300',		name:'つよいこ'},
		{brand:'snowbean',subbrand:'tsuyoiko_14',			name:'つよいこ'},
		{brand:'snowmeg',	subbrand:'pure_820',				name:'ぴゅあ'},
		{brand:'snowmeg',	subbrand:'pure',						name:'ぴゅあ'},
		{brand:'snowmeg',	subbrand:'tacchi_830',			name:'たっち'},
		{brand:'snowmeg',	subbrand:'tacchi',					name:'たっち'},
		{brand:'glico',		subbrand:'balance_800',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'balance_320',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'balance',					name:'アイクレオ'},
		{brand:'glico',		subbrand:'growup_820',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'growup',					name:'アイクレオ'},
		{brand:'glico',		subbrand:'akachan',					name:'アイクレオ'},
	]
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)
	let ret = `${subbrand.node.description}`
	for(let rename of renames){
		if((brand.node.cid === rename.brand) && (subbrand.node.subbrand_id === rename.subbrand)){
			ret = rename.name
			break
		}
	}
	return ret;
}

function getShopId(data, edge){
	const shopId = edge.node.itemList[0].shopId
	return shopId;
}

function nameShop(data, edge){
	const shopId = edge.node.itemList[0].shopId
	const shop = edge.node.itemList[0].shop
	let ret = ''
	if(shopId === 'aeon'){
		ret = 'イオン'
	}
	else if(shopId === 'akachan'){
		ret = 'アカホン'
	}
	else if(shopId === 'amazon'){
		if(shop.includes('定期')){
			ret = 'Amazon定期便'
		}else{
			ret = 'Amazon'
		}
	}
	else if(shopId === 'babiesrus'){
		ret = 'ベビーザらス'
	}
	else if(shopId === 'lohaco'){
		ret = 'LOHACO'
	}
	else if(shopId === 'matsukiyo'){
		ret = 'マツキヨ'
	}
	else if(shopId === 'rakuten'){
		if(shop.includes('ベビー館')){
			ret = '楽天24ベビー館'
		}else{
			ret = '楽天'
		}
	}
	else if(shopId === 'yahoo'){
		ret = 'ヤフショ'
	}
	else if(shopId === 'aupay'){
		ret = 'au PAY'
	}
	else if(shopId === 'nishimatsuya'){
		ret = '西松屋'
	}
	else if(shopId === 'cainz'){
		ret = 'カインズ'
	}
	else if(shopId === 'costco'){
		ret = 'コストコ'
	}
	else if(shopId === 'tsuruha'){
		ret = 'ツルハ'
	}
	else if(shopId === 'kohnan'){
		ret = 'コーナン'
	}
	else if(shopId === 'shimamura'){
		ret = 'しまむら'
	}
	return ret;
}

function linkUrl(data, type, size, edge, shop_id, cp_id){
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)

	const premium_subbrands = ['pampers_haji','pampers_hada','merries_firs','moony_nat','moony_tei','pampers_pure','pampers_chyou','pampers_chyou_familiar','whito_3h','whito_12h']
	const long_subbrands = ['oyas','yoru','gungun','oyab','oyag','max','nikids','nijuni','asa','anshin','sara_oyas','mashb_oyab','mashg_oyag']
	const toitore_subbrands = ['toreb','toreg','sotu','byebye','toire','onii','onee']

	let category =  ((type.node.cid !== 'milk_powder') && (type.node.cid !== 'milk_liquid'))? 'index' : 'milk'

	if(premium_subbrands.includes(brand.node.cid + '_' + subbrand.node.subbrand_id)){ category = 'premium' }
	else if(long_subbrands.includes(subbrand.node.subbrand_id)){	category = 'longtime'	}
	else if(toitore_subbrands.includes(subbrand.node.subbrand_id)){	category = 'toitore' }

	let ret = `/${category}/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${shop_id}`
	if((brand.node.cid === 'mamabear') || (brand.node.cid === 'whito') || (brand.node.cid === 'mirafeel') || (brand.node.cid === 'pigeon') || (brand.node.cid === 'keiseidou') || (brand.node.cid === 'babiesrus')){
		ret = `/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${subbrand.node.subbrand_id}.html`
	}
	if((brand.node.cid === 'ah') || (brand.node.cid === 'ame') || (brand.node.cid === 'topvalue') || (brand.node.cid === 'iris') || (brand.node.cid === 'dacco') || (brand.node.cid === 'matsukiyo')){
		ret = `/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${subbrand.node.subbrand_id}.html`
	}
	if((cp_id === 'CPh') || (cp_id === 'CPi')){
		ret = `/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${subbrand.node.subbrand_id}.html`
	}
	return ret;
}

export const query = graphql`
	query {
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
		allTypesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsMilkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit
							use
							pack_unit
						}
					}
				}
			}
		}
		allContentfulItems {
			edges {
				node {
					brandId
					subbrandId
					typeId
					sizeId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
	}
`
export default TableDiaperCampaign
